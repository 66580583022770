<template>
  <span>
    <v-tooltip
      top
      v-for="item in value.duties"
      :key="'duty' + item.id + item.code"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" right>{{
          studentDutyIcon(item)
        }}</v-icon>
      </template>
      <span>{{ item.description }} </span>
    </v-tooltip>
    <v-tooltip
      top
      v-for="item in value.special"
      :key="'duty' + item.id + item.code"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" right>{{
          studentSpecialIcon(item)
        }}</v-icon>
      </template>
      <span>{{ item.description }} </span>
    </v-tooltip>
  </span>
</template>
<script>
import { defineComponent } from "vue";
import { studentDutyIcon, studentSpecialIcon } from "common/utils/icons.js";
export default defineComponent({
  name: "PersonFlags",
  props: {
    value: { type: Object },
  },
  methods: {
    studentDutyIcon,
    studentSpecialIcon,
  },
});
</script>
