var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item)?_c('v-bottom-sheet',{attrs:{"inset":"","max-width":"500"},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-system-bar',{attrs:{"color":"success"}},[_vm._v("Portfolio"),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sheet = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"title pt-8 text-center"},[_vm._v(" "+_vm._s(_vm.item.title)),_c('br')]),_c('v-card-text',{staticClass:"display text-center"},[_c('v-avatar',[_c('PortraitImage',{attrs:{"value":_vm.item.student}})],1),_c('br'),_c('PersonName',{attrs:{"value":_vm.item.student}})],1),_c('v-card-text',[_c('Markdown',{attrs:{"value":_vm.item.text}})],1),_c('v-card-text',{staticClass:"pt-2 text-center"},[_c('v-chip',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.formatDate(_vm.item.creationDate))+", "+_vm._s(_vm.formatTime(_vm.item.creationTime)))]),_c('v-chip',{staticClass:"mx-1"},[_c('PersonName',{attrs:{"value":_vm.item.creator,"ext":""}})],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.apiReport({
            resource: 'report/portfolio',
            data: {
              type: 'portfolio',
              id: _vm.item.id,
            },
            open: true,
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_vm._v("Portfolio öffnen (pdf)")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.apiReport({
            resource: 'report/portfolio',
            data: {
              type: 'portfolio',
              id: _vm.item.id,
            },
            openInNew: true,
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-open-in-new")])],1),_c('v-list-item-content',[_vm._v("Portfolio in neuem Tab öffnen (pdf)")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.apiReport({
            resource: 'report/portfolio',
            data: {
              type: 'portfolio',
              id: _vm.item.id,
            },
            download: true,
          })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-list-item-content',[_vm._v("Portfolio herunterladen (pdf) ")])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":function($event){_vm.sheet = false}}},[_vm._v("schliessen")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }